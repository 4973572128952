:root {
  --navbar-dark: rgba(31, 31, 31, 1);
  --navbar-light: rgba(49, 49, 49, 1);
  --navbar-border: rgba(123, 123, 123, 1);
  --ted-red: #BC0C2A;
  --transparent: #ff000000;
  --white: #ffffff;

  --variable-collection-blue: rgba(24, 160, 251, 1);
  --variable-collection-green: rgba(27, 196, 125, 1);
  --variable-collection-light: rgba(255, 255, 255, 0.08);
  --variable-collection-mainbg: rgba(16, 16, 16, 1);
  --variable-collection-mid-light: rgba(255, 255, 255, 0.4);
  --variable-collection-purple: rgba(144, 124, 255, 1);
  --variable-collection-subtext: rgba(255, 255, 255, 0.75);
  --variable-collection-text: rgba(255, 255, 255, 1);
}
