@import "fonts.css";

.text {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
  height: 100%;
  width: 100%;
  justify-content: center;  
}

.text .ted-title-container {
  height: 46px;
  position: relative;
  width: 195px;
}

.text .card-titlex {
  height: 46px;
  width: 111px;
  font-family: "Inter", Helvetica;
}

.text .card-title {
  font-size: 45.2px;
  font-weight: 900;
  line-height: 46px;
  white-space: nowrap;
}

.text .card-title-tedx {
  position: absolute;
  left: 0;
  top: 0;
}

.text .ted-text-x {
  font-size: 27.5px;
  font-weight: 900;
  left: 90px;
  line-height: 28px;
  position: absolute;
  top: 3px;
  white-space: nowrap;
}

.text .ted-text-uw {
  font-family: "Inter", Helvetica;
  font-size: 45.2px;
  font-weight: 100;
  left: 115px;
  line-height: 46px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.text .ted-text-platform {
  font-family: "Nunito-Italic", Helvetica;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  letter-spacing: -0.12px;
  line-height: normal;
  position: relative;
  text-align: center;
  width: fit-content;
}

.text .adobe {
  font-family: "AdobeClean", Helvetica;
  position: relative;
}

.text .challenge {
  font-family: "ClashDisplay", Helvetica;
  font-weight: 500;
  position: relative;
}

.cat-box {
  background: linear-gradient(#292929, #292929) padding-box,
              radial-gradient(ellipse at top, #868686, #545454) border-box;
  border-radius: 4px;
  border: 1px solid transparent;
  margin-top: 8px;
}

.cat-in-box { 
  height: 150px;
  margin-left: auto;
  margin-right: auto;
}

.formerly-box {
  padding: 12px;
}

.formerly {
  color: #ffffff66;
  font-family: "Nunito", Helvetica;
  font-size: 12px;
  letter-spacing: -0.5px;
  font-weight: 400;
  position: relative;
  text-align: center;
  white-space: nowrap;
  padding-top: 2px;
}

.nav-border {
  padding: 1px;
  background: radial-gradient(ellipse at top, #999, #333);
  border-radius: 24px;
}

.nav {
  background: #1f1f1f;
  padding: 8px 20px;
  border-radius: 24px;
}

.non-nav-border {
  padding: 1px;
}

.non-nav {
  padding: 8px 20px;
}

.scard-caption {
  text-align: end;
  padding: 8px 12px;
  color: #FFFFFF66;
  font-style: italic;
}

.wide-prototype {
  width: 85vw;
  max-width: 85vw;
  height: calc(85vw * 9 / 16);
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
}

.left-prototype {
  width: 54vw;
  max-width: 54vw;
  height: calc(85vw * 9 / 16);
}

.right-prototype {
  width: 31vw;
  max-width: 31vw;
  height: calc(42vw * 9 / 16);
}

.big-left-prototype {
  width: 70vw;
  max-width: 70vw;
  height: calc(70vw * 9 / 16);
}

.right-img {
  width: 15vw;
  max-width: 15vw;
}

.centered-2 {
  margin-left: auto;
  margin-right: auto;
  max-width: 300px;
  padding: 12px;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 300px;
  padding: 12px;
}

.snowflake {
  position: relative;
}

.scrollbox {
  overflow-y: scroll;
  visibility: hidden;
}

.scrollbox:hover,
.scrollbox-inner {
  visibility: visible;
}
